import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';

// Models
import { bindToContent, CmsEntities } from 'client/data/models/cms';

// Utils
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { TrackingHandler } from 'client/tracking/handler';
import { getResearchCategoryLandingBreadcrumbs } from 'site-modules/editorial/utils/breadcrumbs';
import { getCategoryLandingContentPath } from 'site-modules/editorial/utils/content-path';
import { getIsOptimizedTraffic } from 'site-modules/shared/utils/appraisal/optimized-traffic';

// Constants
import { EditorialMap } from 'client/tracking/maps/editorial';
import { HomepageAdExtensionMap } from 'client/tracking/maps/common/homepage-ad-extension';
import {
  SELL_MY_CAR_ENTRY_ID,
  ENTRY_SHARED_ID,
  ENTRY_ID,
} from 'site-modules/editorial/components/sell-car-research-entry/constants';

// Shared components
import { PageLinksWidget } from 'site-modules/shared/components/page-links-widget/page-links-widget';

// Components
import { SellCarResearchEntry } from 'site-modules/editorial/components/sell-car-research-entry/sell-car-research-entry';
import { SellCarHeadingSection } from 'site-modules/editorial/components/sell-car-heading-section/sell-car-heading-section';

import 'site-modules/editorial/styles/sell-car-page.scss';

const COMBINED_PAGE_BLOCK_ENTRIES = [SELL_MY_CAR_ENTRY_ID, ENTRY_SHARED_ID.PHOTO, ENTRY_ID.SELL_CAR_PROMO];

const COMBINED_PAGE_BLOCK_ENTRIES_ARTICLES = [ENTRY_ID.ARTICLES, ENTRY_ID.SELL_CAR_ARTICLES];

const isCombinedEntry = entry => COMBINED_PAGE_BLOCK_ENTRIES.includes(entry.id);
const isCombinedArticleEntry = entry => COMBINED_PAGE_BLOCK_ENTRIES_ARTICLES.includes(entry.id);

function SellCarLandingUI(props) {
  const { content, category, splat, location, isMobile, isAppExtensionPage } = props;

  useEffect(() => {
    TrackingHandler.useMap({ ...EditorialMap, ...HomepageAdExtensionMap });
  }, []);

  const isOptimizedTraffic = getIsOptimizedTraffic(props);
  const isOptimizedTrafficLanding =
    isOptimizedTraffic &&
    ['/sell-car'].some(pattern => [pattern, `${pattern}/`, `${pattern}/index.app`].includes(location.pathname));

  const title = content?.title ?? '';
  const subtitle = content?.subtitle ?? '';

  const getResearchEntryEl = useCallback(
    (entry, additionalProps = {}) => (
      <SellCarResearchEntry
        entry={entry}
        pathname={location.pathname}
        isMobile={isMobile}
        isBackgroundStyleTabEnabled
        isOptimizedTrafficLandingPage={isOptimizedTrafficLanding}
        {...additionalProps}
      />
    ),
    [isMobile, isOptimizedTrafficLanding, location.pathname]
  );

  if (!content) {
    return null;
  }

  const mainEntries = content.child('main').children();

  const pageEntries = isOptimizedTraffic ? mainEntries.slice(0, 2) : mainEntries;
  const breadcrumbsData = getResearchCategoryLandingBreadcrumbs({
    category,
    splat,
    title: content.metadata('breadcrumbTitle').value(content.title),
  });

  const filteredEntries = isOptimizedTrafficLanding
    ? pageEntries.filter(entry => [SELL_MY_CAR_ENTRY_ID].includes(entry.id))
    : pageEntries;
  const combinedEntries = filteredEntries.filter(entry => isCombinedEntry(entry));
  const combinedArticleEntries = filteredEntries.filter(entry => isCombinedArticleEntry(entry));
  const pageEntriesNotCombined = filteredEntries.filter(
    entry => !isCombinedEntry(entry) && !isCombinedArticleEntry(entry)
  );

  return (
    <main id="main-content" className="sell-car-landing research-category-sell-car sell-car-page">
      <SellCarHeadingSection
        title={title}
        subtitle={subtitle}
        breadcrumbsData={breadcrumbsData}
        isAppExtensionPage={isAppExtensionPage}
        isSellCarLanding
      />
      <Container>
        {!!combinedEntries.length && (
          <Col xs={12} lg={10} className="px-0">
            <div className="sell-car-page-block bg-white py-2_5 px-1 px-md-2_5 mb-1_5">
              {combinedEntries.map((entry, index) => {
                const key = `main-${entry.id}`;

                const researchEntryEl = getResearchEntryEl(entry, {
                  hasSeparator: index !== combinedEntries.length - 1,
                  isCombinedEntry: true,
                  key,
                });

                if (entry.id !== SELL_MY_CAR_ENTRY_ID) {
                  // There's a 1px space on top of the bottom of the <main> if <Col> is used outside of <Row> here
                  return (
                    <Row key={key}>
                      <Col xs={12} lg={10}>
                        {researchEntryEl}
                      </Col>
                    </Row>
                  );
                }

                return researchEntryEl;
              })}
            </div>
          </Col>
        )}
        {!!combinedArticleEntries.length && (
          <Col xs={12} lg={10} className="px-0">
            <div className="sell-car-page-block bg-white py-2_5 px-1 px-md-2_5 mb-1_5">
              {combinedArticleEntries.map((entry, index) =>
                getResearchEntryEl(entry, {
                  hasSeparator: index !== combinedArticleEntries.length - 1,
                  isCombinedEntry: true,
                  key: `main-${entry.id}`,
                })
              )}
            </div>
          </Col>
        )}
        {pageEntriesNotCombined.map(entry => {
          const researchEntryEl = getResearchEntryEl(entry);

          return (
            <Fragment key={`main-${entry.id}`}>
              {entry.id !== SELL_MY_CAR_ENTRY_ID ? (
                <Col xs={12} lg={10} className="px-0">
                  {researchEntryEl}
                </Col>
              ) : (
                researchEntryEl
              )}
            </Fragment>
          );
        })}

        {!isOptimizedTraffic && <PageLinksWidget divideSections={false} title="Related information" hasTopDivider />}
      </Container>
    </main>
  );
}

SellCarLandingUI.propTypes = {
  category: PropTypes.string.isRequired,
  splat: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  content: CmsEntities.Content,
  isMobile: PropTypes.bool,
  isAppExtensionPage: PropTypes.bool,
};

SellCarLandingUI.defaultProps = {
  splat: null,
  location: {},
  content: null,
  isMobile: false,
  isAppExtensionPage: false,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
  isAppExtensionPage: state?.pageContext?.isAppExtensionPage,
});

export const SellCarLanding = connect(mapStateToProps)(
  connectToModel(SellCarLandingUI, {
    content: bindToContent(({ category, splat }) => getCategoryLandingContentPath(category, splat)),
  })
);
