import React from 'react';
import { compact } from 'lodash';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { PUB_STATES } from 'client/constants/pub-states';
import { buildPubStateModelYearPath, VehicleModel } from 'client/data/models/vehicle';
import { StatesModel } from 'client/data/models/states';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { CmsModel } from 'client/data/models/cms';
import { removeSpecialCharacters } from 'site-modules/shared/utils/string';
import { getResearchCategoryName } from 'site-modules/editorial/utils/research-article';
import { EdmundsNoAdsDecorator } from 'site-modules/shared/pages/decorators/edmunds-no-ads';
import { getPageLinksPath, LinkWidgetsModel } from 'client/data/models/link-widgets';
import { getModelState } from 'client/data/luckdragon/model';
import { parseContent } from 'client/data/cms/content';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getSeoObject } from 'site-modules/editorial/pages/research-category/seo';
import { getResearchCategoryLandingBreadcrumbs } from 'site-modules/editorial/utils/breadcrumbs';
import { getSeoImage } from 'site-modules/shared/utils/seo-helper';
import { getCategoryLandingContentPath } from 'site-modules/editorial/utils/content-path';
import { getIsOptimizedTraffic } from 'client/site-modules/shared/utils/appraisal/optimized-traffic';
import { setCriticalCss } from 'client/actions/critical-css';
import { getIsEmoSearchEnabled } from 'client/site-modules/shared/utils/multi-offer/multi-offer';
import { SELL_CAR } from 'site-modules/editorial/constants/slugs';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

import { SellCarLanding } from './sell-car-landing';

const PAGE_NAME_MAP = {
  'sell-car_sell-my-lease': 'advice_sell_lease',
};

export const pageDefinitionConfiguration = (category, splat) => {
  const contentPath = getCategoryLandingContentPath(category, splat);
  const categorySplatName = compact([category, splat]).join('_');

  return {
    name: PAGE_NAME_MAP[categorySplatName] || `advice_${getResearchCategoryName(category)}_article_index`,
    category: 'advice',
    adsCustomTargeting: {
      sect1: removeSpecialCharacters(category),
    },
    decorator: EdmundsNoAdsDecorator,
    preload: store => {
      const {
        pageContext: { location, isAppExtensionPage },
      } = store.getState();
      const preloader = new ModelPreloader(store);

      preloader.resolve(`content["${contentPath}"]`, CmsModel);
      preloader.resolve(getPageLinksPath(), LinkWidgetsModel);
      preloader.resolve(buildPubStateModelYearPath([PUB_STATES.NEW_USED, PUB_STATES.USED]), VehicleModel);
      preloader.resolve('allStateCodes', StatesModel);

      return preloader.load().then(() => {
        const state = store.getState();
        const isOptimizedFunnelTraffic = getIsOptimizedTraffic({ location, isAppExtensionPage });
        const isEmoSearchEnabled = getIsEmoSearchEnabled(location);
        const isEmo2622Chal =
          ExperimentUtil.getForcedOrAssignedRecipeName({
            state,
            campaignName: 'EMO-2622',
            defaultVal: 'ctrl',
          }) === 'chal1';

        const criticalCssKeys = {
          isOptimizedFunnelTraffic, // 2 values
          isAppExtensionPage, // 2 values
          isEmoSearchEnabled, // 2 values
          isEmo2622Chal, // 2 values
        };

        store.dispatch(setCriticalCss(criticalCssKeys));
      });
    },
    seo: state => {
      const content = parseContent(getModelState(state).get(`content["${contentPath}"]`, CmsModel));
      const title = content?.title ?? '';
      const breadcrumbTitle = content.metadata('breadcrumbTitle').value(title);
      const seoObject = getSeoObject(
        state,
        contentPath,
        null,
        getBreadcrumbSchema(getResearchCategoryLandingBreadcrumbs({ category, splat, title: breadcrumbTitle }))
      );

      return {
        ...seoObject,
        image: getSeoImage(content),
      };
    },
    component: props => <SellCarLanding category={category} splat={splat} {...props} />,
    options: {
      isEditorialPageWithInlineMobileAd: true,
    },
  };
};

export default ({ splat }) => pageDefinition(pageDefinitionConfiguration(SELL_CAR, splat));
