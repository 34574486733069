export const ENTRY_RENDER_TYPE = {
  DEFAULT: 'default',
  GRID: 'grid',
  LIST: 'list',
  VIDEO: 'video',
  VIDEOS: 'videos',
  VIDEOS_GRID: 'videos-grid',
  LINKS_LIST: 'links-list',
  AD: 'ad',
  HTML: 'html',
  MODULE: 'module',
  CARD: 'card',
};

export const SELL_MY_CAR_ENTRY_ID = 'sell-my-car-v2';

export const ENTRY_ID = {
  ARTICLES: 'articles',
  SELL_MY_CAR: SELL_MY_CAR_ENTRY_ID,
  HTML: 'html',
  RATINGS: 'ratings',
  SELL_CAR_PROMO: 'sell-car-promo',
  SELL_CAR_ARTICLES: 'sell-car-articles',
};

export const ENTRY_SHARED_ID = {
  MORE_ON_EDMUNDS: 'more-on-edmunds',
  RESEARCH_SUBNAV: 'research-subnav',
  CALCULATORS: 'calculators',
  EDITORIAL_HIGH_IMPACT: 'editorial-high-impact',
  FAQ: 'faq',
  PHOTO: 'photo',
};
